import { ATTACHMENT_PAGE } from "~/configs/consts";

export type ClientId = string;

export type SelfEmployed = "self_employed";
export type IndividualEntrepreneur = "individual_entrepreneur";
export type Company = "company";

export enum OrgTypeEnum {
  SelfEmployed = "self_employed",
  IndividualEntrepreneur = "individual_entrepreneur",
  Company = "company"
}

export enum NameStepsEnum {
  CompanyDetails = "company-details",
  ContactDetails = "contact-details",
  PayformDetails = "payform-details",
  AccountDetails = "account-details",
  PayformTechDetails = "payform-tech-details",
  MarketplaceDetails = "marketplace-details",
  AttachmentsDetails = "attachments-details",
  AdditionalDetails = "additional-details"
}

export enum NameStepsEnumPartner {
  CompanyDetails = "partner-company-details",
  AccountDetails = "partner-account-details",
  AttachmentsDetails = "partner-attachments-details"
}

export enum NamesOfFieldEnum {
  OrgType = "org_type",
  Name = "name",
  Inn = "inn",
  Ogrn = "ogrn",
  Ogrnip = "ogrnip",
  Kpp = "kpp",
  BankName = "bank_name",
  SettlementAccount = "settlement_account",
  CorrespondentAccount = "correspondent_account",
  BankBic = "bank_bic",
  LegalAddress = "legal_address",
  LegalAddressFiasId = "legal_address_fias_id",
  PostalAddress = "postal_address",
  PostalAddressFiasId = "postal_address_fias_id",
  VatMode = "vat_mode",
  Vat = "vat",
  SignatureInfo = "signature_info",
  LprName = "lpr_name",
  LprPhone = "lpr_phone",
  BuhName = "buh_name",
  BuhPhone = "buh_phone",
  TechName = "tech_name",
  TechPhone = "tech_phone",
  PayformPhone = "payform_phone",
  PayformEmail = "payform_email",
  PayformLinks = "payform_links",
  CrmName = "crm_name",
  CrmPhone = "crm_phone",
  CrmEmail = "crm_email",
  CrmSocialLinks = "crm_social_links",
  CrmTelegram = "crm_telegram",
  CrmOccupation = "crm_occupation",
  PaymentNotificationEmail = "payment_notification_email",
  ProductType = "product_type",
  ProductName = "product_name",
  PayformName = "payform_name",
  EducationalMaterialsPlatform = "educational_materials_platform",
  EducationalMaterialsPlatformCustom = "educational_materials_platform_custom",
  GiftAgreedAiTranslationService = "gift_agreed_ai_translation_service",
  PayformPaymentMethods = "payform_payment_methods",
  ClientIntegrationInfo = "client_integration_info",
  ShopName = "shop_name",
  ProductDescription = "product_description",
  LogoFileId = "logo_file_id",
  PassportFileId = "passport_file_id",
  ClientFrom = "client_from",
  ClientPromocode = "client_promocode",
  ClientLengthOfService = "client_length_of_service",
  IsWantToBecomePartner = "is_want_to_become_partner",
  IsHaveEducationalLicense = "is_have_educational_license",
  IsOffertAccepted = "is_offert_accepted",
  PaymentMethodProdamusSubscription = "payment_method_prodamus_subscription",
  PromocodeReferral = "promocode_referral",
  PromocodeOwn = "promocode_own",
  PartnerLevel = "partner_level",
  IsWantToBecomePartnerXl = "is_want_to_become_partner_xl",
  PassportMainFileId = "passport_main_file_id",
  PassportRegistrationFileId = "passport_registration_file_id"
}

export type FormData = Partial<AccountDetailsFormFields> &
  Partial<AdditionalDetailsFormFields> &
  Partial<CompanyDetailsFormFields> &
  Partial<ContactDetailsFormFields> &
  Partial<MarketplaceDetailsFormFields> &
  Partial<PayformDetailsFormFields> &
  Partial<PayformTechDetailsFormFields> &
  Partial<AttachmentsDetailsFormFields> &
  Partial<AccountDetailsFormFieldsPartner> &
  Partial<CompanyDetailsFormFieldsPartner> &
  Partial<AttachmentsDetailsFormFieldsPartner>;

export type SharedTypeRequestFields = AccountDetailsFormFields &
  AdditionalDetailsFormFields &
  CompanyDetailsFormFields &
  CompanyDetailsFormFields &
  ContactDetailsFormFields &
  MarketplaceDetailsFormFields &
  PayformDetailsFormFields &
  PayformTechDetailsFormFields &
  AccountDetailsFormFieldsPartner &
  CompanyDetailsFormFieldsPartner &
  AttachmentsDetailsFormFieldsPartner;

export interface AccountDetailsFormFields {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.CrmPhone]: string;
  [NamesOfFieldEnum.IsWantToBecomePartner]: "yes" | "no";
  [NamesOfFieldEnum.CrmName]: string;
  [NamesOfFieldEnum.CrmEmail]: string;
  [NamesOfFieldEnum.CrmSocialLinks]: string;
}

export interface AdditionalDetailsFormFields {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.ClientFrom]: string;
  [NamesOfFieldEnum.ClientPromocode]: string;
  [NamesOfFieldEnum.ClientLengthOfService]: "first_run" | "lower_than_year" | "greater_than_year";
}

export interface CompanyDetailsFormFields {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.Name]: string;
  [NamesOfFieldEnum.Ogrn]: string;
  [NamesOfFieldEnum.Ogrnip]: string;
  [NamesOfFieldEnum.Kpp]: string;
  [NamesOfFieldEnum.VatMode]: "orn" | "npd";
  [NamesOfFieldEnum.Vat]: "no" | "0" | "10" | "20";
  [NamesOfFieldEnum.BankName]: string;
  [NamesOfFieldEnum.SettlementAccount]: string;
  [NamesOfFieldEnum.CorrespondentAccount]: string;
  [NamesOfFieldEnum.BankBic]: string;
  [NamesOfFieldEnum.LegalAddress]: string;
  [NamesOfFieldEnum.LegalAddressFiasId]: string;
  [NamesOfFieldEnum.PostalAddress]: string;
  [NamesOfFieldEnum.PostalAddressFiasId]: string;
  [NamesOfFieldEnum.SignatureInfo]: string;
  [NamesOfFieldEnum.Inn]: string;
}

export interface ContactDetailsFormFields {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.LprName]: string;
  [NamesOfFieldEnum.LprPhone]: string;
  [NamesOfFieldEnum.BuhName]: string;
  [NamesOfFieldEnum.BuhPhone]: string;
  [NamesOfFieldEnum.TechName]: string;
  [NamesOfFieldEnum.TechPhone]: string;
}

export interface MarketplaceDetailsFormFields {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.ShopName]: string;
  [NamesOfFieldEnum.ProductDescription]: string;
}

export interface PayformDetailsFormFields {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.PayformPhone]: string;
  [NamesOfFieldEnum.PayformEmail]: string;
  [NamesOfFieldEnum.PayformLinks]: string;
}

export interface PayformTechDetailsFormFields {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.PayformName]: string;
  [NamesOfFieldEnum.PaymentNotificationEmail]: string;
  [NamesOfFieldEnum.ProductType]: string;
  [NamesOfFieldEnum.ProductName]: string;
  [NamesOfFieldEnum.PayformPaymentMethods]: string[];
  [NamesOfFieldEnum.ClientIntegrationInfo]: string;
  [NamesOfFieldEnum.EducationalMaterialsPlatform]: string;
  [NamesOfFieldEnum.EducationalMaterialsPlatformCustom]: string;
  [NamesOfFieldEnum.GiftAgreedAiTranslationService]: "yes" | "no";
  [NamesOfFieldEnum.IsHaveEducationalLicense]: "yes" | "no";
  [NamesOfFieldEnum.PaymentMethodProdamusSubscription]: "settlement_account" | "other";
}

export interface AttachmentsDetailsFormFields {
  [NamesOfFieldEnum.LogoFileId]?: number[];
  [NamesOfFieldEnum.PassportFileId]?: number[];
  [NamesOfFieldEnum.PassportMainFileId]?: number[];
  [NamesOfFieldEnum.PassportRegistrationFileId]?: number[];
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.IsOffertAccepted]: 1;
}

export type AttachmentsDetailsError = null | {
  has: 0 | 1;
  message: string;
};

/**  ------ partner steps ------  */
export interface AccountDetailsFormFieldsPartner {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.CrmName]: string;
  [NamesOfFieldEnum.CrmEmail]: string;
  [NamesOfFieldEnum.CrmPhone]: string;
  [NamesOfFieldEnum.CrmTelegram]: string;
  [NamesOfFieldEnum.CrmSocialLinks]: string;
  [NamesOfFieldEnum.CrmOccupation]: string;
  [NamesOfFieldEnum.PromocodeReferral]: string;
  [NamesOfFieldEnum.PromocodeOwn]: string;
  [NamesOfFieldEnum.PartnerLevel]: string;
  [NamesOfFieldEnum.IsWantToBecomePartnerXl]: string[];
}

export interface CompanyDetailsFormFieldsPartner {
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.Inn]: string;
  [NamesOfFieldEnum.Ogrn]: string;
  [NamesOfFieldEnum.BankName]: string;
  [NamesOfFieldEnum.BankBic]: string;
  [NamesOfFieldEnum.Kpp]: string;
  [NamesOfFieldEnum.SettlementAccount]: string;
  [NamesOfFieldEnum.CorrespondentAccount]: string;
  [NamesOfFieldEnum.LegalAddress]: string;
  [NamesOfFieldEnum.LegalAddressFiasId]: string;
}

export interface AttachmentsDetailsFormFieldsPartner {
  [NamesOfFieldEnum.PassportMainFileId]?: number[];
  [NamesOfFieldEnum.PassportRegistrationFileId]?: number[];
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.IsOffertAccepted]: 1;
}

/**  ------ partner steps end ------  */

export type TypeInput = "input" | "textArea" | "inputNumber";

export type ConfigNamesFields = Partial<Record<NamesOfFieldEnum, string>>;

export type StatusConfirmationCode =
  | "initial-request-code"
  | "pending-request-code"
  | "resend-request-code"
  | "send-without-request-code";
export type StatusPage = "default" | typeof ATTACHMENT_PAGE | StatusConfirmationCode;

export type TypeSuggestionData = {
  fias_id: string;
  value: string;
  value_short: string;
  type: string;
};

export enum NamesOfFieldBankInfoEnum {
  BankName = "bank_name",
  BankBic = "bank_bic",
  BankInn = "bank_inn",
  BankKpp = "bank_kpp",
  BankCorrespondentAccount = "bank_correspondent_account"
}

export type TypeBankInfo = {
  [NamesOfFieldBankInfoEnum.BankName]: string;
  [NamesOfFieldBankInfoEnum.BankInn]: string;
  [NamesOfFieldBankInfoEnum.BankBic]: string;
  [NamesOfFieldBankInfoEnum.BankKpp]: string;
  [NamesOfFieldBankInfoEnum.BankCorrespondentAccount]: string;
};

export type ResponseDefaultFields = {
  success: 1 | 0;
  fields: ConfigNamesFields;
};

