import { KEY_SMS_CONFIRMATION_FEATURE_FLAG } from "~/configs/consts";
import { getEnabledFlags } from "./getEnabledFlags";
import { getTypeQuestionnaires } from "./getTypeQuestionnaires";

export const getCountOfStepsInAnketa = ({ defaultCount }: { defaultCount: number }) => {
  const enabledFlags = getEnabledFlags();

  if (enabledFlags.includes(KEY_SMS_CONFIRMATION_FEATURE_FLAG)) {
    defaultCount += 1;
  }

  if (getTypeQuestionnaires() === "partner") {
    return 4;
  }

  return defaultCount;
};
