export type TypeQuestionnaires = "partner" | "customer" | "unknown";

export function getTypeQuestionnaires(): TypeQuestionnaires {
  const path = window.location.pathname;
  const segments = path.split("/").filter(Boolean);
  const route = segments[0];
  
  if (route === "partner") {
    return "partner";
  }
  if (route === "customer") {
    return "customer";
  }

  return "unknown";
}
