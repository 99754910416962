import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Layout } from "~/components/layout";
import {
  ErrorPage,
  EmailSuccessPage,
  EmailFailedPage,
  CustomerQuestionnairePage,
  PartnerQuestionnairePage
} from "~/pages";
import { SentryContrainer } from "~/containers";
import { routes } from "./configs/routes";

import "~/assets/styles/style.scss";
import "~/assets/icons/index";

export const App = (): JSX.Element => {
  return (
    <SentryContrainer>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate replace to={routes.customer} />} />
            <Route path={routes.customer} element={<CustomerQuestionnairePage />} />
            <Route path={routes.partner} element={<PartnerQuestionnairePage />} />
            <Route path={routes.emailSuccess} element={<EmailSuccessPage />} />
            <Route path={routes.emailFailed} element={<EmailFailedPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </SentryContrainer>
  );
};

/**
 * 2) account-details - какие значения могут быть в  account-details ? - стрингой
 * 3) Ступень партнерской программы	Выбор (1, 2, 3) с описанием каждой	- Миша
 * 4) Выбор доп программ отдельно Телебосс или XL, не обязательно - радио баттон? на беке тогда поменять - цифра
 * 5) Только от партнера 3 ступени. Автоматом предзаполнен если перешел по реф. ссылке - какая ссылка ?
 * 
 */