// @ts-nocheck
import cn from "classnames";
import { ERROR_FIELD_ } from "~/configs/consts";

import styles from "./RadioField.module.scss";

export const RadioField = ({ input, children, meta, orderNumber, disabled, classNameInput, classNameWrapper }: any) => {
  const hasError = (meta?.error || meta?.submitError) && meta?.submitFailed;

  return (
    <label
      className={cn(styles.container, classNameWrapper, {
        [styles.disabledLabel]: disabled
      })}
    >
      <input
        type="radio"
        {...input}
        id={hasError ? `${ERROR_FIELD_ + orderNumber}` : undefined}
        disabled={disabled}
        className={cn(classNameInput, {
          [styles.inputDisabled]: disabled
        })}
      />
      {children}
    </label>
  );
};
