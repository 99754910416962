import { Dispatch, SetStateAction } from "react";
import { FormField } from "../form-field";
import { AttachmentsDetailsError, OrgTypeEnum } from "~/typings/types";
import { CheckboxField } from "~/components";
import { URL_OFERTA, URL_PRIVACY, URL_TARIF } from "~/configs/consts";

import styles from "./AcceptPolitics.module.scss";

export const GetAcceptsPoliticsTitle = ({ orgType }: any) => {
  const text =
    orgType === OrgTypeEnum.SelfEmployed
      ? "Я ознакомлен и принимаю в полном объеме условия"
      : "Я ознакомлен c условиями";
  return (
    <p className={styles.text}>
      {text} предоставления услуг, положения{" "}
      <a href={URL_OFERTA} target="_blank" className={styles.textBlueLink}>
        Оферты
      </a>
      ,{" "}
      <a href={URL_TARIF} target="_blank" className={styles.textBlueLink}>
        Тарифами
      </a>{" "}
      и{" "}
      <a href={URL_PRIVACY} target="_blank" className={styles.textBlueLink}>
        Политики конфиденциальности
      </a>{" "}
      .
    </p>
  );
};

interface Props {
  acceptPoliticsError: AttachmentsDetailsError;
  isAcceptPolitics: boolean;
  setIsAcceptPolitics: Dispatch<SetStateAction<boolean>>;
  setAcceptPoliticsError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  orgType?: OrgTypeEnum;
}

export const AcceptPolitics = ({
  acceptPoliticsError,
  isAcceptPolitics,
  setIsAcceptPolitics,
  setAcceptPoliticsError,
  orgType
}: Props) => {
  return (
    <div className={styles.container}>
      <FormField name="accept-politics" renderError={acceptPoliticsError}>
        <CheckboxField
          fieldName="accept-politics"
          name={isAcceptPolitics}
          checked={isAcceptPolitics}
          title={<GetAcceptsPoliticsTitle orgType={orgType} />}
          styleIndicatorProps={{ top: 4 }}
          onChange={(event: any) => {
            if (!isAcceptPolitics) {
              setAcceptPoliticsError(null);
            }
            setIsAcceptPolitics(event);
          }}
        />
      </FormField>
    </div>
  );
};
