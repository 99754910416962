import { clientApi } from "~/utils/clientApi";
import { DefaultAxiosRequestConfig } from "~/api/apiMethods";
import {
  DRAFT_PREVIEW,
  DRAFT_COMPANY_DETAILS,
  DRAFT_CONTACT_DETAILS,
  DRAFT_PAYFORM_DETAILS,
  DRAFT_ACCOUNT_DETAILS,
  DRAFT_PAYFORM_TECH_DETAILS,
  DRAFT_MARKETPLACE_DETAILS,
  DRAFT_ATTACHMENTS_DETAILS,
  DRAFT_ADDITIONAL_DETAILS
} from "./apiUrls";
import {
  AccountDetailsFormFields,
  AccountDetailsFormFieldsPartner,
  AdditionalDetailsFormFields,
  AttachmentsDetailsFormFields,
  ClientId,
  CompanyDetailsFormFields,
  CompanyDetailsFormFieldsPartner,
  ContactDetailsFormFields,
  MarketplaceDetailsFormFields,
  NameStepsEnum,
  NameStepsEnumPartner,
  PayformDetailsFormFields,
  PayformTechDetailsFormFields
} from "~/typings/types";

export type ResSuccess = 1 | 0;

export interface ReqPostData<T> {
  id: ClientId;
  is_presave_mode?: boolean;
  fields: T;
}

export interface ReqPostArguments<T> {
  data: ReqPostData<T>;
  config?: DefaultAxiosRequestConfig;
}

export interface ErrorFieldFromBackend {
  code: "field_validation" | "confirmation:incorrect_code" | "code_confirmation:too_many_attempts";
  message: string;
  data: {
    target_type: string;
    target: string;
  };
}

export interface ResPost {
  success: ResSuccess;
  errors?: ErrorFieldFromBackend[];
}

export interface ApiResponseGetClientIdByHash {
  success: ResSuccess;
  data: {
    id: ClientId;
  };
}

interface DraftPrewievId {
  clientId: string;
}

export interface ApiResponseDraftPreviewId {
  success: 1 | 0;
  data: {
    questionnaire_id: ClientId;
    chapters: Partial<{
      [NameStepsEnum.AccountDetails]: AccountDetailsFormFields;
      [NameStepsEnum.AdditionalDetails]: AdditionalDetailsFormFields;
      [NameStepsEnum.CompanyDetails]: CompanyDetailsFormFields;
      [NameStepsEnum.ContactDetails]: ContactDetailsFormFields;
      [NameStepsEnum.MarketplaceDetails]: MarketplaceDetailsFormFields;
      [NameStepsEnum.PayformDetails]: PayformDetailsFormFields;
      [NameStepsEnum.PayformTechDetails]: PayformTechDetailsFormFields;

      [NameStepsEnumPartner.AccountDetails]: AccountDetailsFormFieldsPartner;
      [NameStepsEnumPartner.CompanyDetails]: CompanyDetailsFormFieldsPartner;
    }>;
    flat: FormData;
    ready_to_save: 1 | 0;
    errors: [];
    chapter_latest_int: number;
    chapter_latest: NameStepsEnum;
    shortcut_hash: string;
    should_generate_new_id?: boolean;
  };
}

export const getDraftPreviewId = async ({
  clientId
}: DraftPrewievId): Promise<ApiResponseDraftPreviewId> => {
  try {
    const res = await clientApi.get(`${DRAFT_PREVIEW}/${clientId}`);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getDraftPreviewId");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftCompanyDetails = async ({
  data,
  config
}: ReqPostArguments<CompanyDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_COMPANY_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftContactDetails = async ({
  data,
  config
}: ReqPostArguments<ContactDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_CONTACT_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftPayformDetails = async ({
  data,
  config
}: ReqPostArguments<PayformDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_PAYFORM_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftAccountDetails = async ({
  data,
  config
}: ReqPostArguments<AccountDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_ACCOUNT_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftPayformTechDetails = async ({
  data,
  config
}: ReqPostArguments<PayformTechDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_PAYFORM_TECH_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftMarketplaceDetails = async ({
  data,
  config
}: ReqPostArguments<MarketplaceDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_MARKETPLACE_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftAttachmentsDetails = async ({
  data,
  config
}: ReqPostArguments<AttachmentsDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_ATTACHMENTS_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftAdditionalDetails = async ({
  data,
  config
}: ReqPostArguments<AdditionalDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_ADDITIONAL_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};
