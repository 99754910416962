import { useState, useEffect, useRef } from "react";
import { useDebounce } from "usehooks-ts";
import { Field } from "react-final-form";
import { Input, List, Spin } from "antd";
import styles from "./Autocomplete.module.scss";
import { disabledValidate } from "~/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { useSuggestions } from "./hooks/useSuggestions";
import { TypeSuggestionData } from "~/typings/types";

const antIcon = <LoadingOutlined className={styles.spin} spin />;

export const AutocompleteInput = ({
  name,
  nameFiasId,
  clientId,
  propsSpy,
  validate,
  disabled,
  setErrorGetSuggestions,
  urlPersonalDataAddressSuggest
}: any) => {
  const [query, setQuery] = useState("");
  const [selectedFiasId, setSelectedFiasId] = useState<string | null>(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const listItemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { suggestions, setSuggestions, isPendingResponseSuggestions, fetchSuggestions } =
    useSuggestions(clientId, setErrorGetSuggestions, urlPersonalDataAddressSuggest);

  const debouncedQuery = useDebounce(query, 1000);

  useEffect(() => {
    if (debouncedQuery) {
      fetchSuggestions(debouncedQuery);
    } else {
      setSuggestions([]);
    }
  }, [debouncedQuery]);

  useEffect(() => {
    if (suggestions.length > 0) {
      setFocusedIndex(0);
    } else {
      setFocusedIndex(-1);
    }
  }, [suggestions]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setSuggestions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (focusedIndex !== -1 && listItemRefs.current[focusedIndex]) {
      listItemRefs.current[focusedIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  }, [focusedIndex]);

  const handleInputChange = (inputValue: string) => {
    if (inputValue.trim() === "") {
      return;
    }
    setSelectedFiasId(null);
    propsSpy.form.change(nameFiasId, null);
    setQuery(inputValue);
    setFocusedIndex(-1);
  };

  const handleSuggestionClick = (suggestion: TypeSuggestionData, input: any) => {
    input.onChange(suggestion.value);
    setSelectedFiasId(suggestion.fias_id);
    propsSpy.form.change(nameFiasId, suggestion.fias_id);
    setSuggestions([]);
    setFocusedIndex(-1);
  };

  const handleKeyDown = (event: any, input: any) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1));
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex(prevIndex => Math.max(prevIndex - 1, 0));
    } else if (event.key === "Enter" && focusedIndex >= 0 && suggestions.length > 0) {
      event.preventDefault();
      handleSuggestionClick(suggestions[focusedIndex], input);
    }
  };

  return (
    <Field name={name} validate={disabledValidate(validate)}>
      {({ input }) => (
        <div style={{ width: "100%", margin: "0 auto", position: "relative" }}>
          <div className={styles.containerInput}>
            <Input
              disabled={disabled}
              {...input}
              onChange={event => {
                input.onChange(event);
                handleInputChange(event.target.value);
              }}
              onKeyDown={event => handleKeyDown(event, input)}
            />
            {isPendingResponseSuggestions && (
              <Spin indicator={antIcon} className={styles.containerSpin} />
            )}
          </div>
          {suggestions.length > 0 && (
            <div
              ref={suggestionsRef}
              style={{
                marginTop: "4px",
                width: "100%",
                maxHeight: "150px",
                overflowY: "auto",
                backgroundColor: "white",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                zIndex: 1000
              }}
            >
              <List
                dataSource={suggestions}
                renderItem={(suggestion, index) => (
                  <List.Item
                    key={index}
                    ref={el => (listItemRefs.current[index] = el)}
                    onClick={() => handleSuggestionClick(suggestion, input)}
                    className={focusedIndex === index ? styles.focusedListItem : styles.listItem}
                    onMouseEnter={() => setFocusedIndex(index)}
                  >
                    {suggestion.value}
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>
      )}
    </Field>
  );
};
