import { NamesOfFieldEnum, ConfigNamesFields, NamesOfFieldBankInfoEnum } from "~/typings/types";

export const configTitleFieldsOfIndividualEntrepreneur: ConfigNamesFields = {
  [NamesOfFieldEnum.OrgType]: "ИП",
  [NamesOfFieldEnum.Name]: "Ф.И.О.",
  [NamesOfFieldEnum.Inn]: "ИНН",
  [NamesOfFieldEnum.Ogrn]: "ОГРН",
  [NamesOfFieldEnum.Ogrnip]: "ОГРНИП",
  [NamesOfFieldEnum.Kpp]: "КПП",
  [NamesOfFieldEnum.BankName]: "Название банка",
  [NamesOfFieldEnum.SettlementAccount]: "Расчётный счёт",
  [NamesOfFieldEnum.CorrespondentAccount]: "Корреспондентский счёт",
  [NamesOfFieldEnum.BankBic]: "БИК",
  [NamesOfFieldEnum.LegalAddress]: "Адрес регистрации по паспорту",
  [NamesOfFieldEnum.PostalAddress]: "Почтовый адрес",
  [NamesOfFieldEnum.Vat]: "Являетесь ли плательщиком НДС?",
  [NamesOfFieldEnum.VatMode]: "Вы зарегистрированы как самозанятый?",
  [NamesOfFieldEnum.SignatureInfo]: "Должность и Ф.И.О. лица, подписывающего договор",
  [NamesOfFieldEnum.LprName]: "Ф.И.О. ЛПР",
  [NamesOfFieldEnum.LprPhone]: "Телефон ЛПР",
  [NamesOfFieldEnum.BuhName]: "Ф.И.О. бухгалтера",
  [NamesOfFieldEnum.BuhPhone]: "Телефон бухгалтера",
  [NamesOfFieldEnum.TechName]: "Ф.И.О. технического специалиста",
  [NamesOfFieldEnum.TechPhone]: "Телефон технического специалиста",
  [NamesOfFieldEnum.PayformPhone]: "Номер телефона для покупателей",
  [NamesOfFieldEnum.PayformEmail]: "Email",
  [NamesOfFieldEnum.PayformLinks]:
    "Ссылка на сайт или соцсеть с описанием Вашего сервиса и с которого проходят его продажи",
  [NamesOfFieldEnum.CrmName]: "Ф.И.О.",
  [NamesOfFieldEnum.CrmPhone]: "Мобильный телефон",
  [NamesOfFieldEnum.CrmEmail]: "E-mail",
  [NamesOfFieldEnum.CrmSocialLinks]: "Ссылка на профиль в соцсетях",
  [NamesOfFieldEnum.CrmTelegram]: "Никнейм в ТГ",
  [NamesOfFieldEnum.PaymentNotificationEmail]: "E-mail для уведомлений об оплате",
  [NamesOfFieldEnum.ProductType]: "Что будете продавать?",
  [NamesOfFieldEnum.ProductName]: "Универсальное название товара или услуги",
  [NamesOfFieldEnum.PayformName]: "Название платёжной страницы латиницей",
  [NamesOfFieldEnum.EducationalMaterialsPlatform]:
    "На какой платформе размещены учебные материалы и информация о ваших услугах?",
  [NamesOfFieldEnum.PayformPaymentMethods]:
    "Какие из перечисленных вариантов оплаты сделать доступными для ваших покупателей?",
  [NamesOfFieldEnum.ClientIntegrationInfo]:
    "Перечислите сервисы и платформы с которыми потребуются интеграции для осуществления продаж",
  [NamesOfFieldEnum.ShopName]: "Название магазина",
  [NamesOfFieldEnum.ProductDescription]: "Описание оказываемых услуг",
  [NamesOfFieldEnum.LogoFileId]: "Ваш логотип",
  [NamesOfFieldEnum.PassportFileId]: "Скан паспорта",
  [NamesOfFieldEnum.ClientFrom]: "Откуда вы о нас узнали?",
  [NamesOfFieldEnum.ClientPromocode]: "Промокод",
  [NamesOfFieldEnum.ClientLengthOfService]: "Давно ли вы на рынке онлайн-образования?",
  [NamesOfFieldEnum.IsHaveEducationalLicense]: "Есть ли у вас образовательная лицензия?",
  [NamesOfFieldEnum.PaymentMethodProdamusSubscription]:
    "Каким способом вам удобней оплатить подключение к Продамус?",
  [NamesOfFieldEnum.CrmOccupation]: "Сфера Вашей деятельности?",
  [NamesOfFieldEnum.PromocodeReferral]: "Промокод Партнера, который порекомендовал Prodamus",
  [NamesOfFieldEnum.PromocodeOwn]: "Придумайте свой промокод",
  [NamesOfFieldEnum.PartnerLevel]: "Выберите ступень партнерской программы",
  [NamesOfFieldEnum.IsWantToBecomePartnerXl]: "Планируете ли стать партнером XL/Teleboss",
  [NamesOfFieldEnum.PassportMainFileId]: "Файл паспорта основная",
  [NamesOfFieldEnum.PassportRegistrationFileId]: "Файл паспорта регистрация"
};

export const configTitleFieldsOfBank = {
  [NamesOfFieldBankInfoEnum.BankName]: "Название банка",
  [NamesOfFieldBankInfoEnum.BankInn]: "БИК банка",
  [NamesOfFieldBankInfoEnum.BankBic]: "ИНН банка",
  [NamesOfFieldBankInfoEnum.BankKpp]: "КПП банка",
  [NamesOfFieldBankInfoEnum.BankCorrespondentAccount]: "Корреспондентский счёт банка"
};