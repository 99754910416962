import { getKeyClientId } from "./getKeyClientId";

export const getClientIdFromStorage = (): string | null => {
  try {
    const item = window.localStorage.getItem(getKeyClientId());
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return null;
  }
};
