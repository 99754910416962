import { useState, useCallback } from "react";
import { clientApi } from "~/utils";
import { ClientId, NamesOfFieldEnum, OrgTypeEnum, ResponseDefaultFields } from "~/typings/types";
import { PERSONAL_DATA_BY_INN } from "~/api/apiUrls";

export interface UseFetchPersonalDataByInn {
  id: ClientId;
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.Inn]: string;
}

export function useFetchPersonalDataByInn({ id, org_type, inn }: UseFetchPersonalDataByInn) {
  const [data, setData] = useState<ResponseDefaultFields | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPersonalDataByInn = useCallback(async () => {
    try {
      setIsLoading(true);
      setData(null);
      setError(null);

      const result = await clientApi.post(PERSONAL_DATA_BY_INN, {
        id,
        org_type,
        inn
      });
      await setData(result.data);
    } catch (error: any) {
      setError("Метод: useFetchPersonalDataByInn вернул ошибку.");
    } finally {
      setIsLoading(false);
    }
  }, [id, org_type, inn]);

  return { fetchPersonalDataByInn, data, isLoading, error };
}
