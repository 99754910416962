import { useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { TitlePage, RadioField } from "~/components";
import {
  composeValidators,
  disabledValidate,
  notEmptyValue,
  required,
  testRegExp
} from "~/utils/validate";
import { Divider } from "antd";
import {
  Wizard,
  FormField,
  CustomInnField,
  FormFieldAutocompleteAddress,
  FormFieldBic
} from "~/pages/questionnaires/shared";
import { ClientId, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";
import {
  configTitleFieldsOfBank,
  configTitleFieldsOfIndividualEntrepreneur
} from "../configTitleFields";
import {
  GET_BANK_INFO,
  PERSONAL_DATA_ADDRESS_SUGGEST
} from "~/pages/questionnaires/customer/api/apiUrls";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

interface Props {
  clientId: ClientId;
  orgType: OrgTypeEnum;
}

export const CompanyDetails = ({ clientId, orgType }: Props) => {
  /** До проверки ИНН все поля в этом шаге должны быть disable */
  const [isDisabledFieldsByInn, setIsDisabledFieldsByInn] = useState(true);

  return (
    <Wizard.Page>
      <TitlePage name="Укажите свои реквизиты" number="3" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <CustomInnField
          innLength={12}
          orderNumber="1"
          orgType={orgType}
          clientId={clientId}
          configNameFields={configTitleFieldsOfIndividualEntrepreneur}
          setIsDisabledFieldsByInn={setIsDisabledFieldsByInn}
        />

        <FormSpy>
          {propsSpy => {
            return (
              <FormFieldBic
                titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.BankBic]}
                name={NamesOfFieldEnum.BankBic}
                required
                validate={composeValidators(
                  required(),
                  testRegExp(/^04(\d){7}$/g, 'БИК должен начинаться на "04" и состоять из 9 цифр')
                )}
                disabled={isDisabledFieldsByInn}
                configNameFieldsModalSuggestion={configTitleFieldsOfBank}
                propsSpy={propsSpy}
                clientId={clientId}
                tooltip="БИК — уникальный код банка. Он состоит из девяти цифр"
                urlGetBankInfo={GET_BANK_INFO}
              />
            );
          }}
        </FormSpy>

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.BankName]}
          name={NamesOfFieldEnum.BankName}
          required
          validate={composeValidators(required())}
          orderNumber="4"
          disabled={isDisabledFieldsByInn}
        />

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.Ogrnip]}
          name={NamesOfFieldEnum.Ogrnip}
          maxLength={15}
          mask="3"
          required
          validate={composeValidators(
            required(),
            testRegExp(/^3(\d){14}$/g, 'ОГРНИП должен начинаться на "3" и состоять из 15 цифр')
          )}
          orderNumber="3"
          disabled={isDisabledFieldsByInn}
        />

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.SettlementAccount]}
          name={NamesOfFieldEnum.SettlementAccount}
          maxLength={20}
          mask="408"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^408(\d){17}$/g,
              'Номер расчётного счёта должен начинаться на "408" и состоять из 20 цифр'
            )
          )}
          orderNumber="5"
          disabled={isDisabledFieldsByInn}
        />

        <FormField
          titleField={
            configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.CorrespondentAccount]
          }
          name={NamesOfFieldEnum.CorrespondentAccount}
          maxLength={20}
          mask="301"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^301(\d){17}$/g,
              'Номер корреспондентского счёта должен начинаться на "301" и состоять из 20 цифр'
            )
          )}
          orderNumber="6"
          disabled={isDisabledFieldsByInn}
        />

        <FormSpy>
          {propsSpy => {
            return (
              <FormFieldAutocompleteAddress
                titleField={
                  configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.LegalAddress]
                }
                name={NamesOfFieldEnum.LegalAddress}
                nameFiasId={NamesOfFieldEnum.LegalAddressFiasId}
                required
                validate={composeValidators(required(), notEmptyValue())}
                disabled={isDisabledFieldsByInn}
                propsSpy={propsSpy}
                clientId={clientId}
                urlPersonalDataAddressSuggest={PERSONAL_DATA_ADDRESS_SUGGEST}
              />
            );
          }}
        </FormSpy>
      </div>
    </Wizard.Page>
  );
};
