import { type AxiosRequestConfig } from "axios";
import { clientApi } from "~/utils/clientApi";
import { TAXONOMY_PAYMENT_METHOD, EDUCATIONAL_PLATFORM, TAXONOMY_PRODUCT_TYPE } from "./apiUrls";
import { ClientId } from "~/typings/types";

export const defaultAxiosRequestConfig: DefaultAxiosRequestConfig = {
  retry: 10,
  retryDelay: 10000
};

export type ResSuccess = 1 | 0;

export interface ReqPostData<T> {
  id: ClientId;
  is_presave_mode?: boolean;
  fields: T;
}

export interface ReqPostArguments<T> {
  data: ReqPostData<T>;
  config?: DefaultAxiosRequestConfig;
}

export interface ErrorFieldFromBackend {
  code: "field_validation" | "confirmation:incorrect_code" | "code_confirmation:too_many_attempts";
  message: string;
  data: {
    target_type: string;
    target: string;
  };
}

export interface ResPost {
  success: ResSuccess;
  errors?: ErrorFieldFromBackend[];
}

export interface DefaultAxiosRequestConfig extends AxiosRequestConfig {
  retry?: number;
  retryDelay?: number;
}

interface ApiResponseSharedDataFromBackend {
  success: ResSuccess;
  data: SharedDataFromBackend;
}


export type SharedDataFromBackend = Record<string, { name: string; title: string }>;

export const getPaymentMethod = async (): Promise<ApiResponseSharedDataFromBackend> => {
  try {
    const res = await clientApi.get(TAXONOMY_PAYMENT_METHOD);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getPaymentMethod");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getProductType = async (): Promise<ApiResponseSharedDataFromBackend> => {
  try {
    const res = await clientApi.get(TAXONOMY_PRODUCT_TYPE);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getProductType");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getEducationalPlatform = async (): Promise<ApiResponseSharedDataFromBackend> => {
  try {
    const res = await clientApi.get(EDUCATIONAL_PLATFORM);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getEducationalPlatform");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};
