import { TitlePage } from "~/components";
import { Divider } from "antd";
import { Wizard, Confirmation, PropsConfirmation } from "~/pages/questionnaires/shared";
import { KEY_SMS_CONFIRMATION_FEATURE_FLAG } from "~/configs/consts";
import { getEnabledFlags } from "~/utils";

export const ConfirmationCode = (props: PropsConfirmation) => {
  const enabledFlags = getEnabledFlags();

  if (!enabledFlags.includes(KEY_SMS_CONFIRMATION_FEATURE_FLAG)) {
    return null;
  }

  return (
    <Wizard.Page>
      <TitlePage name="Подтверждение телефона" number="10" />
      <Divider style={{ margin: 0 }} />
      <Confirmation {...props} />
    </Wizard.Page>
  );
};
