// @ts-nocheck
import { Field } from "react-final-form";
import { TitlePage, PhoneField } from "~/components";
import { composeValidators, disabledValidate, required, testRegExp } from "~/utils/validate";
import { Divider } from "antd";
import { Wizard, FormField } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfIndividualEntrepreneur } from "../configTitleFields";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

export const PayformDetails = () => {
  return (
    <Wizard.Page>
      <TitlePage
        name="Укажите контактные данные вашей организации, которые будут размещены на платёжной странице"
        number="5"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.PayformPhone]}
          name={NamesOfFieldEnum.PayformPhone}
          required
          tooltip="Обязательное требование платёжных систем"
          orderNumber="1"
          triggerAutofill={[NamesOfFieldEnum.PayformPhone, NamesOfFieldEnum.CrmPhone]}
        >
          <Field
            name={NamesOfFieldEnum.PayformPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            validate={disabledValidate(
              composeValidators(
                required(),
                testRegExp(/^[+7]/, "Номер телефона должен начинаться на +7")
              )
            )}
            component={props => <PhoneField orderNumber="1" onlyCountries={["ru"]} {...props} />}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.PayformEmail]}
          name={NamesOfFieldEnum.PayformEmail}
          tooltip="Если не хотите указывать свой email на платёжной странице, оставьте поле пустым"
          orderNumber="2"
          triggerAutofill={[NamesOfFieldEnum.PayformEmail, NamesOfFieldEnum.CrmEmail]}
        />

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.PayformLinks]}
          name={NamesOfFieldEnum.PayformLinks}
          tooltip="Если ресурсов несколько, то укажите все через запятую"
          validate={composeValidators(required())}
          required
          orderNumber="3"
        />
      </div>
    </Wizard.Page>
  );
};
