import { Dispatch, useState } from "react";
import { TypeSuggestionData } from "~/typings/types";
import { clientApi } from "~/utils";
import { GetErrorSuggestions } from "../FormFieldAutocompleteAddress";

export const useSuggestions = (
  clientId: string,
  setErrorGetSuggestions: Dispatch<GetErrorSuggestions>,
  url: string
) => {
  const [suggestions, setSuggestions] = useState<TypeSuggestionData[]>([]);
  const [isPendingResponseSuggestions, setIsPendingResponseSuggestions] = useState(false);

  const fetchSuggestions = async (query: string) => {
    try {
      setIsPendingResponseSuggestions(true);
      setErrorGetSuggestions(null);

      const data = await clientApi.post(url, {
        id: clientId,
        q: query
      });
      setSuggestions(data.data.fields);
    } catch (error: any) {
      setErrorGetSuggestions(error?.response?.data?.error?.[0]);
    } finally {
      setIsPendingResponseSuggestions(false);
    }
  };

  return {
    suggestions,
    setSuggestions,
    isPendingResponseSuggestions,
    fetchSuggestions
  };
};
