import React, { type FC, useState } from "react";

import {
  ClientId,
  ConfigNamesFields,
  NamesOfFieldBankInfoEnum,
  NamesOfFieldEnum,
  TypeBankInfo
} from "~/typings/types";
import { ErrorField, TitleField, Modal } from "~/components";
import { AutocompleteInput, ModalContent } from "./components";
import { useModal } from "~/hooks";

import styles from "./FormFieldAutocompleteBic.module.scss";

export type ErrorRequest = {
  code: string;
  message: string;
} | null;

interface Props {
  name: string;
  clientId: ClientId;
  titleField?: string;
  validate?: any;
  required?: boolean;
  tooltip?: string;
  isColon?: boolean;
  styleContainerTitleField?: any;
  styleContainer?: any;
  placeholder?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  renderError?: any;
  propsSpy: any;
  configNameFieldsModalSuggestion: ConfigNamesFields;
  mask?: string;
  urlGetBankInfo: string;
}

export const FormFieldBic: FC<Props> = ({
  name,
  clientId,
  titleField,
  validate,
  required,
  tooltip,
  isColon,
  styleContainerTitleField,
  styleContainer,
  renderError,
  configNameFieldsModalSuggestion,
  propsSpy,
  disabled,
  mask,
  urlGetBankInfo
}) => {
  const [errorRequest, setErrorRequest] = useState<ErrorRequest>(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState<TypeBankInfo>();

  const customErrorField =
    renderError || (errorRequest && { has: 1, message: errorRequest.message });

  const {
    isShow: isShowModal,
    open: openModal,
    toggle: toggleModal,
    close: closeModal
  } = useModal();

  const handleSelectedSuggestions = (suggestion: TypeBankInfo) => {
    propsSpy.form.change(NamesOfFieldEnum.BankName, "");
    propsSpy.form.change(NamesOfFieldEnum.CorrespondentAccount, "");

    setSelectedSuggestion(suggestion);
    openModal();
  };

  const handleSuccessModalSelectedSuggestions = () => {
    propsSpy.form.change(
      NamesOfFieldEnum.BankName,
      selectedSuggestion![NamesOfFieldBankInfoEnum.BankName]
    );
    propsSpy.form.change(NamesOfFieldEnum.BankBic, selectedSuggestion![NamesOfFieldBankInfoEnum.BankBic]);
    propsSpy.form.change(
      NamesOfFieldEnum.CorrespondentAccount,
      selectedSuggestion![NamesOfFieldBankInfoEnum.BankCorrespondentAccount]
    );

    closeModal();
  };
  const handleRejectModalSelectedSuggestions = () => {
    closeModal();
  };

  return (
    <div className={styles.containerField} style={styleContainer}>
      {titleField && (
        <TitleField
          name={titleField}
          required={required}
          tooltip={tooltip}
          isColon={isColon}
          styleContainer={styleContainerTitleField}
        />
      )}

      <div className={styles.containerInput}>
        <AutocompleteInput
          name={name}
          validate={validate}
          disabled={disabled}
          setErrorRequest={setErrorRequest}
          handleSelectedSuggestions={handleSelectedSuggestions}
          mask={mask}
          urlGetBankInfo={urlGetBankInfo}
        />
      </div>

      <ErrorField name={name} renderError={customErrorField} />

      {selectedSuggestion && (
        <Modal isShow={isShowModal} toggle={toggleModal}>
          <ModalContent
            fields={selectedSuggestion}
            configNameFields={configNameFieldsModalSuggestion}
            handleClickSuccess={handleSuccessModalSelectedSuggestions}
            handleClickReject={handleRejectModalSelectedSuggestions}
          />
        </Modal>
      )}
    </div>
  );
};
