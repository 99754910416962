import { Field } from "react-final-form";
import { TitlePage, PhoneField, CheckboxField } from "~/components";
import { composeValidators, disabledValidate, required, testRegExp } from "~/utils/validate";
import { Divider } from "antd";
import { Wizard, FormField, GetCrmSocialLinksTitle } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfIndividualEntrepreneur } from "../configTitleFields";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

export const AccountDetails = () => {
  return (
    <Wizard.Page>
      <TitlePage
        name="Укажите ваши данные для регистрации аккаунта в системе Prodamus"
        number="2"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.CrmName]}
          name={NamesOfFieldEnum.CrmName}
          required
          validate={composeValidators(required())}
          orderNumber="1"
        />

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.CrmPhone]}
          name={NamesOfFieldEnum.CrmPhone}
          required
        >
          <Field
            name={NamesOfFieldEnum.CrmPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            component={props => <PhoneField orderNumber="2" {...props} />}
            validate={disabledValidate(composeValidators(required()))}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.CrmEmail]}
          name={NamesOfFieldEnum.CrmEmail}
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^\s*\S+@\S+\.\S+\s*$/,
              "Пожалуйста, укажите корректный адрес электронной почты"
            )
          )}
          orderNumber="3"
        />

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.CrmSocialLinks]}
          name={NamesOfFieldEnum.CrmSocialLinks}
        />

        {/* Задача https://prodamus.kaiten.ru/space/138816/card/29408041
        <div style={{ marginBottom: 28 }}>
          <CheckboxField
            fieldName={NamesOfFieldEnum.IsWantToBecomePartner}
            name="yes"
            orderNumber="4"
            title={<GetCrmSocialLinksTitle />}
          />
        </div> */}
      </div>
    </Wizard.Page>
  );
};
