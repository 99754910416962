import { clientApi } from "~/utils/clientApi";
import { DefaultAxiosRequestConfig } from "~/api/apiMethods";
import {
  DRAFT_PREVIEW,
  PARTNER_DRAFT_ACCOUNT_DETAILS,
  PARTNER_DRAFT_COMPANY_DETAILS,
  PARTNER_DRAFT_ATTACHMENTS_DETAILS
} from "./apiUrls";
import {
  AccountDetailsFormFields,
  AttachmentsDetailsFormFields,
  ClientId,
  CompanyDetailsFormFields,
  NameStepsEnum
} from "~/typings/types";

export type ResSuccess = 1 | 0;

export interface ReqPostData<T> {
  id: ClientId;
  is_presave_mode?: boolean;
  fields: T;
}

export interface ReqPostArguments<T> {
  data: ReqPostData<T>;
  config?: DefaultAxiosRequestConfig;
}

export interface ErrorFieldFromBackend {
  code: "field_validation" | "confirmation:incorrect_code" | "code_confirmation:too_many_attempts";
  message: string;
  data: {
    target_type: string;
    target: string;
  };
}

export interface ResPost {
  success: ResSuccess;
  errors?: ErrorFieldFromBackend[];
}

export interface ApiResponseGetClientIdByHash {
  success: ResSuccess;
  data: {
    id: ClientId;
  };
}

interface DraftPrewievId {
  clientId: string;
}

export interface ApiResponseDraftPreviewId {
  success: 1 | 0;
  data: {
    questionnaire_id: ClientId;
    chapters: Partial<{
      [NameStepsEnum.AccountDetails]: AccountDetailsFormFields;
      [NameStepsEnum.CompanyDetails]: CompanyDetailsFormFields;
    }>;
    flat: FormData;
    ready_to_save: 1 | 0;
    errors: [];
    chapter_latest_int: number;
    chapter_latest: NameStepsEnum;
    shortcut_hash: string;
    should_generate_new_id?: boolean;
  };
}

export const getDraftPreviewId = async ({
  clientId
}: DraftPrewievId): Promise<ApiResponseDraftPreviewId> => {
  try {
    const res = await clientApi.get(`${DRAFT_PREVIEW}/${clientId}`);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getDraftPreviewId");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftCompanyDetails = async ({
  data,
  config
}: ReqPostArguments<CompanyDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(PARTNER_DRAFT_COMPANY_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftAccountDetails = async ({
  data,
  config
}: ReqPostArguments<AccountDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(PARTNER_DRAFT_ACCOUNT_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftAttachmentsDetails = async ({
  data,
  config
}: ReqPostArguments<AttachmentsDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(PARTNER_DRAFT_ATTACHMENTS_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};
