// @ts-nocheck
import { TitlePage } from "~/components";
import { composeValidators, required } from "~/utils/validate";
import { Divider } from "antd";
import { Wizard, FormField } from "~/pages/questionnaires/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfIndividualEntrepreneur } from "../configTitleFields";

import styles from "~/pages/questionnaires/shared/Shared.module.scss";

export const MarketplaceDetails = () => {
  return (
    <Wizard.Page>
      <TitlePage name="Укажите информацию для маркетплейса" number="7" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.ShopName]}
          name={NamesOfFieldEnum.ShopName}
          required
          validate={composeValidators(required())}
          tooltip="Например: онлайн-марафон «Как перейти на удалённую работу». Или: интернет-магазин «Ромашка». Или: магазин диетических товаров «Авокадо». Или: тренинги Ивана Иванова"
          orderNumber="1"
        />

        <FormField
          titleField={
            configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.ProductDescription]
          }
          name={NamesOfFieldEnum.ProductDescription}
          required
          validate={composeValidators(required())}
          typeInput="textArea"
          autoSize={{
            minRows: 7
          }}
          showCount
          maxLength={10000}
          tooltip={`Напишите не менее четырёх предложений. Можно в рекламном духе. 
                			Например: «Онлайн-клуб постановки голоса для беременных и молодых мам: открытые уроки и мастер-классы по тренировке голоса, 
                			полезные и простые упражнения, интересные интервью с профильными специалистами по теме беременности, материнства и здоровья. 
                			Получи красивый голос, занимаясь всего 15 минут в день, без отрыва от счастья материнства! 
                			А также: обучение искусству колыбельной песни, сочинения и рассказывания сказок, общение с единомышленницами»
              		`}
          orderNumber="2"
          placement="left"
        />
      </div>
    </Wizard.Page>
  );
};
