import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { RESEND_REQUEST_CODE, SEND_WITHOUT_REQUEST_CODE } from "~/configs/consts";
import { COUNT_REQUEST_CODE } from "~/pages/questionnaires/shared/confirmation-code/useConfirmationCode";
import { StatusConfirmationCode } from "~/typings/types";

type TimerID = ReturnType<typeof setInterval>;

type Props = {
  duration: number;
  setStatusConfirmationCode: Dispatch<SetStateAction<StatusConfirmationCode>>;
};

export function useTimer({ duration, setStatusConfirmationCode }: Props) {
  const timerId = useRef<TimerID>();
  const [seconds, setSeconds] = useState(duration);
  const [isRunning, setIsRnning] = useState(false);
  const [countTryStart, setCountTryStart] = useState(0);

  useEffect(() => {
    if (seconds < 1) {
      if (COUNT_REQUEST_CODE === countTryStart) {
        setStatusConfirmationCode(SEND_WITHOUT_REQUEST_CODE);
      } else {
        setStatusConfirmationCode(RESEND_REQUEST_CODE);
      }
      stop();
    }
  }, [seconds]);

  const start = useCallback(() => {
    timerId.current = setInterval(() => {
      if (seconds === 0) {
        stop();
      }
      setSeconds(seconds => seconds - 1);
    }, 1000);
    setIsRnning(true);
    setCountTryStart(count => (count += 1));
  }, [setSeconds, setIsRnning]);

  const stop = useCallback(() => {
    setIsRnning(false);
    clearInterval(timerId.current);
    setSeconds(duration);
  }, [setIsRnning, setSeconds]);

  useEffect(() => {
    return () => {
      clearInterval(timerId.current);
    };
  }, [stop]);
  return { isRunning, start, stop, seconds, countTryStart, setCountTryStart };
}
