import { useEffect } from "react";
import { OrgTypeEnum } from "~/typings/types";
import {
  DefaultPage,
  Wizard,
  useAttachmentsDetails,
  useConfirmationCode
} from "~/pages/questionnaires/shared";
import { CompanyDetails, AccountDetails, AttachmentsDetails, ConfirmationCode } from "./steps";
import { HELPER_FILE } from "~/pages/questionnaires/customer/api/apiUrls";

const COUNT_UPLOAD_LOGO_FILE = 1;
const COUNT_UPLOAD_PASSPORT_FILE = 1;

export const CompanyStep = ({
  clientId,
  paymentMethod,
  educationalPlatform,
  queryPromocode,
  setOrgType,
  productType,
  ...sharedStepProps
}: any) => {
  useEffect(() => {
    setOrgType(OrgTypeEnum.Company);
  }, []);

  const attachmentsDetails = useAttachmentsDetails({
    clientId,
    orgType: OrgTypeEnum.Company,
    initialLogoFileId: sharedStepProps.initialValues.passport_main_file_id,
    initialPassportFileId: sharedStepProps.initialValues.passport_registration_file_id,
    urlHelperFile: HELPER_FILE
  });

  const confirmationCode = useConfirmationCode({ clientId });

  return (
    <Wizard
      {...sharedStepProps}
      logoFileId={attachmentsDetails.logoFileId}
      handleRequestAttachmentsDetails={attachmentsDetails.handleRequestAttachmentsDetails}
      isLoadingAttachmentsDetails={attachmentsDetails.isLoadingAttachmentsDetails}
      isErrorAttachmentsDetails={attachmentsDetails.isErrorAttachmentsDetails}
      isAcceptPolitics={attachmentsDetails.isAcceptPolitics}
      setAcceptPoliticsError={attachmentsDetails.setAcceptPoliticsError}
      statusConfirmationCode={confirmationCode.statusConfirmationCode}
      handleGetRequestCode={confirmationCode.handleGetRequestCode}
      isLoadingGetRequestCode={confirmationCode.isLoading}
    >
      <DefaultPage />
      <AccountDetails queryPromocode={queryPromocode} />
      <CompanyDetails clientId={clientId} orgType={sharedStepProps.orgType} />
      <AttachmentsDetails
        {...attachmentsDetails}
        COUNT_UPLOAD_LOGO_FILE={COUNT_UPLOAD_LOGO_FILE}
        COUNT_UPLOAD_PASSPORT_FILE={COUNT_UPLOAD_PASSPORT_FILE}
      />
      <ConfirmationCode
        {...confirmationCode}
        clientId={clientId}
        setIsRenderSuccessPage={sharedStepProps.setIsRenderSuccessPage}
        setIsRenderErrorPage={sharedStepProps.setIsRenderErrorPage}
        isLoadingGetRequestCode={confirmationCode.isLoading}
      />
    </Wizard>
  );
};
