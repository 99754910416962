import { Dispatch, useState, type FC, useEffect } from "react";
import { useForm, useFormState } from "react-final-form";
import cn from "classnames";
import { ClientId, ConfigNamesFields, NamesOfFieldEnum, OrgTypeEnum, ResponseDefaultFields } from "~/typings/types";
import { CustomButton, Modal } from "~/components";
import { useModal } from "~/hooks";
import { FormField } from "../form-field";
import { useFetchPersonalDataByInn } from "./useFetchPersonalDataByInn";
import { ModalContent } from "./ModalContent";
import { composeValidators, required, testRegExp } from "~/utils";

import styles from "./CustomInnField.module.scss";

const getContentForModal = (initData: null | string | ResponseDefaultFields) => {
  if (!initData || typeof initData === "string" || initData?.success === 0) {
    return "Мы вас не нашли, но вы можете продолжить заполнение анкеты";
  }
  return initData.fields;
};

interface Props {
  setIsDisabledFieldsByInn: Dispatch<boolean>;
  clientId: ClientId;
  orderNumber: string;
  innLength: number;
  configNameFields: ConfigNamesFields;
  orgType: OrgTypeEnum;
}

export const CustomInnField: FC<Props> = ({
  clientId,
  setIsDisabledFieldsByInn,
  orderNumber,
  innLength,
  configNameFields,
  orgType
}) => {
  const ERROR_MSG_INN_LENGTH = `ИНН должен состоять из ${innLength} цифр`;

  const [initInn, setInitInn] = useState();
  const [isErrorField, setIsErrorField] = useState(false);
  const { change } = useForm();
  const { values } = useFormState();
  const {
    isShow: isShowModal,
    open: openModal,
    toggle: toggleModal,
    close: closeModal
  } = useModal();

  const valueInn = values[NamesOfFieldEnum.Inn];

  const requestData = {
    id: clientId,
    [NamesOfFieldEnum.OrgType]: orgType,
    [NamesOfFieldEnum.Inn]: valueInn
  };

  const {
    fetchPersonalDataByInn,
    data: personalData,
    isLoading: isLoadingPersonalDataByInn,
    error
  } = useFetchPersonalDataByInn(requestData);

  const data = getContentForModal(error ?? personalData);

  const isDisabledCheckButton =
    isErrorField || !valueInn || (initInn === valueInn && valueInn) || isLoadingPersonalDataByInn;

  const handleCheckInn = async () => {
    if (valueInn.length !== innLength) {
      setIsErrorField(true);
      return;
    }
    setInitInn(valueInn);
    await fetchPersonalDataByInn();
    await openModal();
  };

  const handlePersonalDataSuccess = () => {
    if (personalData?.fields) {
      // Автозаполнение полей формы
      Object.entries(personalData.fields).forEach(([nameField, valueField]) => {
        change(nameField, valueField);
      });
    }
    closeModal();
    setIsDisabledFieldsByInn(false);
  };

  const handlePersonalDataReject = () => {
    closeModal();
    setIsDisabledFieldsByInn(false);
  };

  // Блокируем поле ввода, если значение не изменилось после нажатия на кнопку Проверить
  useEffect(() => {
    if (valueInn) {
      setIsDisabledFieldsByInn(false);
    }
  }, []);

  // Для проверки валидации поля, на кол-во введеных символов
  useEffect(() => {
    if (isErrorField && valueInn && valueInn.length === innLength) {
      setIsErrorField(false);
    }
  }, [valueInn]);

  return (
    <div className={styles.container}>
      <FormField
        titleField="Введите ИНН и мы вас найдем"
        name={NamesOfFieldEnum.Inn}
        required
        maxLength={innLength}
        orderNumber={orderNumber}
        styleContainer={{ width: "100%", paddingRight: 16 }}
        validate={composeValidators(
          required(),
          testRegExp(`^(\\d){${innLength}}$`, ERROR_MSG_INN_LENGTH)
        )}
        renderError={
          isErrorField && {
            has: 1,
            message: ERROR_MSG_INN_LENGTH
          }
        }
      />

      <CustomButton
        name="Проверить"
        onClick={handleCheckInn}
        className={cn(styles.buttonCheckInn, {
          [styles.buttonDisabled]: isDisabledCheckButton
        })}
        type="button"
        isLoading={isLoadingPersonalDataByInn}
        disabled={isDisabledCheckButton}
      />

      <Modal isShow={isShowModal} toggle={toggleModal}>
        <ModalContent
          fields={data}
          configNameFields={configNameFields}
          handleClickSuccess={handlePersonalDataSuccess}
          handleClickReject={handlePersonalDataReject}
          orgType={orgType}
        />
      </Modal>
    </div>
  );
};
