import { Dispatch, SetStateAction } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Spin } from "antd";
import cn from "classnames";
import { TitlePage } from "~/components";
import {
  RulesFiles,
  FormField,
  Wizard,
  UploadFile,
  AcceptPolitics
} from "~/pages/questionnaires/shared";
import { AttachmentsDetailsError, NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfCompany } from "../configTitleFields";

import "~/pages/questionnaires/shared/styles.css";
import styles from "~/pages/questionnaires/shared/Shared.module.scss";

interface Props {
  isLoadingMount: boolean;
  isLoadingLogo: boolean;
  logoFiles: File[];
  setLogoFileId: Dispatch<SetStateAction<number[]>>;
  setLogoFiles: Dispatch<File[]>;
  logoError: AttachmentsDetailsError;
  setLogoError: Dispatch<AttachmentsDetailsError>;
  COUNT_UPLOAD_LOGO_FILE?: number;
  isAcceptPolitics: boolean;
  setIsAcceptPolitics: Dispatch<SetStateAction<boolean>>;
  acceptPoliticsError: AttachmentsDetailsError;
  setAcceptPoliticsError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  setIsFirstLoadingFile: Dispatch<boolean>;
}

export const AttachmentsDetails = ({
  isLoadingMount,
  isLoadingLogo,
  logoFiles,
  setLogoFileId,
  setLogoFiles,
  logoError,
  setLogoError,
  COUNT_UPLOAD_LOGO_FILE,
  isAcceptPolitics,
  setIsAcceptPolitics,
  acceptPoliticsError,
  setAcceptPoliticsError,
  setIsFirstLoadingFile
}: Props) => {
  return (
    <Wizard.Page>
      <TitlePage name="Загрузите необходимые документы" number="9" />
      <Divider style={{ margin: 0 }} />
      <div className={cn(styles.container, styles.containerUpload)}>
        {isLoadingMount ? (
          <div className={styles.containerLoader}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#3d935e" }} spin />} />
          </div>
        ) : (
          <>
            <FormField
              titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.LogoFileId]}
              name={NamesOfFieldEnum.LogoFileId}
              tooltip="Наличие логотипа на платежной странице улучшает визуальное восприятие вашими клиентами страницы и увеличивает конверсию в оплату"
              styleContainer={{ marginBottom: 24, minHeight: 150 }}
              renderError={logoError}
            >
              <div>
                <UploadFile
                  id={NamesOfFieldEnum.LogoFileId}
                  uploadedFiles={logoFiles}
                  setUploadedFiles={setLogoFiles}
                  isLoadingProps={isLoadingLogo}
                  setFileid={setLogoFileId}
                  setError={setLogoError}
                  count={COUNT_UPLOAD_LOGO_FILE}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            </FormField>
          </>
        )}
      </div>
      <RulesFiles />
      <Divider style={{ margin: 0 }} />
      <AcceptPolitics
        isAcceptPolitics={isAcceptPolitics}
        setIsAcceptPolitics={setIsAcceptPolitics}
        acceptPoliticsError={acceptPoliticsError}
        setAcceptPoliticsError={setAcceptPoliticsError}
      />
    </Wizard.Page>
  );
};
